// Post style
.markdown-body {
    font-family: $fonts;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 32px;

    .highlight {
        margin: 0 0 16px;
        /** overflow: scroll;  修复代码高亮 显示两个 scroll的问题 **/
    }

    img {
        transition: .3s;
        max-width: 100%;
        max-height: 100%;
        margin: 16px auto 5px;
        display: block;
    }

    .caption {
        display: block;
        text-align: center;
        color: #222222;
        font-size: 13.5px;
    }

    ul,
    ol {
        li {
            p {
                margin: 0;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    mjx-math {
        overflow-x: auto;
        overflow-y: unset;
        width: 100%;
    }

    .copy-container {
        position: relative;
        background-color: #e2e2e2;
        height: 28px;

        .lang {
            line-height: 28px;
            padding-left: 10px;
            font-weight: bold;
        }

        .btn {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 2px 2px;
            border: none;
            cursor: pointer;
            height: 28px;
            background-color: #ebedf0;
            z-index: 100;

            // border-radius: 2px;
            .icon {
                width: 24px;
                height: 24px;
            }

            .tooltip {
                font-family: $fonts;
                font-size: 14px;
                float: right;
                position: absolute;
                margin-left: -38px;
                background-color: #dadde1;
                padding: 3px 7px;
                border-radius: 3px;
                margin-top: -35px;
                display: none;

                &.finish {
                    margin-left: -43px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: calc(100% - 6px);
                    left: calc(50% - 6px);
                    width: 10px;
                    height: 10px;
                    background-color: #dadde1;
                    border-width: 1px;
                    border-style: solid;
                    border-color: transparent #dadde1 #dadde1 transparent;
                    transform: rotate(45deg);
                    clip-path: polygon(145% -50%, 150% 150%, -50% 145%);
                }

                &.active {
                    display: inline-block;
                }
            }
        }
    }

    p {
        font-size: 16px;
        line-height: 1.8;
        text-align: justify;
    }

    h1,
    h2 {
        border-bottom: none;
    }

    ul {
        list-style: revert;
        li {
            line-height: 1.8;
        }
    }

    &>ol {
        padding-left: 0;
    }

    ol {
        list-style: none !important;

        li {
            counter-increment: a 1;

            &:before {
                content: counter(a);
                font-weight: bold;
                color: #fff;
                text-align: center;
                display: inline-block;
                width: 21px;
                font-size: 14px;
                margin-right: 10px;
                border-radius: 50%;
            }

            &::marker {
                content: "";
            }

            li {
                counter-increment: b 1;

                &:before {
                    content: counter(a)"."counter(b);
                    border-radius: 0;
                }

                li {
                    counter-increment: c 1;

                    &:before {
                        content: counter(a)"."counter(b)"."counter(c);
                    }

                    li {
                        counter-increment: d 1;

                        &:before {
                            content: counter(a)"."counter(b)"."counter(c)"."counter(d);
                        }
                    }
                }
            }
        }
    }

    .calendar-top {
        margin: 10px auto;

        .legends {
            float: right;
            font-size: 12px;
            margin: 5px;
            min-width: 150px;

            #ex-ghDay-legend2 {
                display: inline-block;
                margin: 0 4px;
            }
        }
    }

    a.button {
        text-decoration: none;
        padding: 3px 15px;
        display: inline-block;
        border-radius: 5px;
        margin-right: 5px;
        color: $color-dark-primary;

        &:hover {
            background-color: #ebedf0;
        }
    }

    summary {
        &.render-expand {
            padding: 3px 12px;
            font-size: 12px;
            line-height: 20px;
            float: right;
            list-style: none;
            font-weight: 500;
            color: #57606a;
            background-color: #f6f8fa;
            box-shadow: 0 1px 0 rgb(27 31 36 / 4%), inset 0 1px 0 rgb(255 255 255 / 25%);
            border: 1px solid rgba(27, 31, 36, .15);
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background-color: #f3f4f6;
                border-color: rgba(27, 31, 36, 0.15);
                transition-duration: .1s;
            }
        }
    }

    pre {
        &.language-mermaid2 {
            background: #eceff1;
            color: transparent;
            display: none;

            code {
                color: transparent;
                background-color: #eceff1;
            }
        }

        border: 3px solid #e2e2e2;
        border-radius: 0px !important;
        border-top: none;
    }

    .mermaid2 {
        justify-content: center;
        text-align: center;
        align-content: flex-start;
        margin: 10px 0;
        background-color: #fff;
    }

    .category {
        text-transform: capitalize;
    }

    .table-area {
        overflow: auto;
        margin: 0 auto;

        table {
            word-break: keep-all;
            word-wrap: break-word;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 auto;
        }
    }

    .post-copyright {
        background-color: $color-post-copyright-background;
        border-radius: 4px;
        border: 1px solid $color-post-copyright-border;
        padding: 18px 20px;
        font-size: 14px;
        color: $color-post-copyright;

        p {
            margin-bottom: 0px;
            text-align: left;

            span {
                font-weight: bold;
            }

            &.tips {
                color: $color-post-copyright-tips;
                display: none;

                &.active {
                    display: block;
                }
            }
        }
    }

    .language-plaintext {
        color: chocolate;
    }

    .footnotes {
        border-top-width: 3px;
        padding-top: 10px;

        ol li {
            &:before {
                position: relative !important;
                top: 0 !important;
                right: 0 !important;
                bottom: 0 !important;
                left: 0 !important;
                content: counter(a) !important;
                border: 0px !important;
                border-radius: 50% !important;
                font-size: 16px;
            }

            p {
                display: inline;
            }
        }
    }

    #archive-heatmap {
        .icon {
            width: 18px;
            height: 18px;
            margin-bottom: -3px;
            fill: currentColor;
            overflow: hidden;
        }
    }
    #archive-articles {
        ul {
            border-left: 4px solid #efefef;
            padding-left: 0;
            list-style: none;
            li {
                padding-left: 15px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: -4px;
                    width: 4px;
                    border-radius: 12px;
                    height: 4px;
                    top: calc(50% - .5* 4px);
                    background: #cccccc;
                    transition: background .2s ease-out, height .2s ease-out, top .2s ease-out, transform .2s ease-out;
                    -moz-transition: background .2s ease-out, height .2s ease-out, top .2s ease-out, transform .2s ease-out;
                    -webkit-transition: background .2s ease-out, height .2s ease-out, top .2s ease-out, transform .2s ease-out;
                    -o-transition: background .2s ease-out, height .2s ease-out, top .2s ease-out, transform .2s ease-out;
                    transform: scale(2);    
                }
                &:hover:before {
                    border-radius: 0px;
                    height: 15px;
                    top: 7px;
                    width: 2px;
                    left: -3px;
                }
            }
        }
    }
}
/*** Base ***/
html, body {
    height: 100%;
}
html, body, div, span, h1, h2, h3, h4, h5, h6, p, ol, ul, li, textarea, 
form, label, legend, table, caption, menu, nav, section, summary, 
time, mark, audio, video, iframe, main, header, footer {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0 none;
}
body {
    font-family: $fonts;
    color: $color-f-secondary;
    -webkit-font-smoothing: antialiased;
    background-color: $color-bg;
    overflow: visible;
}
ul {
    list-style-type: none;
}
a {
    color: $color-f-primary;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;
}
::selection {
    background-color: rgba(0, 147, 254, .2);
}
::-moz-selection {
    background-color: rgba(0, 147, 254, .2);
}
::-webkit-selection {
    background-color: rgba(0, 147, 254, .2);
}

.gray{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
}

:root {
    --waline-theme-color: #81bbff!important;
    --waline-active-color: #1B7EFF!important;
}
